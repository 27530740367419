.step-number {
  font-size:38px;
  background:#41bdb0;
  border-radius:50%;
  width:50px;
  height:50px;
  display:inline-block;
  margin-right:20px;
  transform:translateY(4px);
  text-align:center;
  line-height:50px;
  color:white;
  margin-top:44px;
}
.step-text {
  font-size:28px;
  line-height:44px;
}
.step-container {
  transform:translateX(20px);
  text-align:left;
  margin-top:40px;
}
.step-img {
  max-width:300px;
  width:80%;
}
