.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hero-container > div > div {
  width:100%;
}
.hero-container {
  position:absolute;
  width:100vw;
}
.spacer {
  width:100vw;
  height:20px;
}
.hero-img-container {
  width:100vw;
  position: absolute;
  overflow: hidden;
  height: 366px;
  filter: brightness(0.7);
}
.intro-card {
  border:none !important;
  margin:48px 0px !important;
}
.intro-card p {
  text-align:justify;
}
.hands-parallax > img {
  width:100%;
}
.intro-title.mobile {
  font-size:40px;
}
.intro-title {
  font-weight:800;
  font-size:54px;
  line-height:54px;
  margin:20px;
  font-family:'roboto';
}
.soft-shadow {
  filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .5));
}
.contact-parallax {
  background-image:url(./components/Contact/contact-splash.jpg);
}
