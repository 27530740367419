.login-form {
  width:250px;
}
.login-form-container {
  background:rgba(255,255,255,0.85);
  width:fit-content;
  margin:auto;
}
.login-form-button {
  width:100%;
  margin-top:16px;
}
.login-form-remember {
  display:block;
  text-align:left;
}
