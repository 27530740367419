ul.catalog-list.mobile li { 
  font-size:16px;
}
ul.catalog-list li { 
  list-style:none;
  text-align:left;
  font-size:20px;
  line-height:30px;
  position:relative;
}
ul.catalog-list li::before { 
  content:" ";
  width:6px;
  height:20px;
  position:absolute;
  left:-15px;
  top:5px;
  background-color:#96bf0e;
}
.demo { 
  width:50%;
}
.catalog-course-title-2 {
  font-size:30px;
  font-weight:bold;
  margin-bottom:20px;
}
