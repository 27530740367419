.footer {
  margin-top:40px;
  padding:20px;
  background-color: #96bf0e;
  background-color: #333;
  color:white;
}
.footer-list {
  list-style: none;
  text-align:left;
}
.footer-list-item {
  color:rgba(255,255,255,0.85);
}
.footer-list-item-content {
}

.footer-list-item-title {
  font-weight:bold;
}
